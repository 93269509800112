import React from "react";
import { useLocation } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import { Link } from "react-router-dom";

export default function Organizations() {
  const location = useLocation();
  return (
    <>
      <div
        style={{
          display: "grid",
          placeItems: "center",
          padding: "20px",
        }}
      >
        <h4>Which Organization are you working for today ?</h4>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {location.state.organizations.map((element) => (
            <Link
              to={`/organizations/${element.id}/projects`}
              state={{ organization_name: element.name }}
            >
              <Card
                className="gradient-card"
                sx={{
                  width: "150px",
                  height: "150px",
                  display: "grid",
                  placeContent: "center",
                }}
              >
                <CardContent
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    color: "#212020",
                  }}
                >
                  <strong style={{ textDecoration: "none" }}>
                    {element.name}
                  </strong>
                </CardContent>
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}
