import React, { useState, useEffect } from "react";
import { CircularProgress, Button } from "@mui/material";
import robotHello from "../../assets/robot-hello.json";
// import { MOCK_USER } from "../../utils/MockData";
import axios from "axios";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../utils/Constants";

export default function Welcome() {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: robotHello,
  };

  useEffect(() => {
    if (window && window.parent) {
      window.parent.postMessage({ functionName: "getCurrentUser" }, "*");
      window.addEventListener("message", (event) => {
        if (event.data.functionName === "getCurrentUser") {
          loadUserData(event.data.email);
        }
      });
    }
  }, []);

  const loadUserData = (email) => {
    axios
      .get(`${BASE_URL}/current-user?email=${email}`)
      .then((response) => {
        setUser(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="container">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Lottie options={defaultOptions} height={200} width={200} />
          <p style={{ fontSize: "15px", textAlign: "center" }}>
            Hello! I am Eloquence! You can call me El. You are {user.first_name}{" "}
            right ?
          </p>
          <Link
            to={"/organizations"}
            state={{ organizations: user.organizations }}
          >
            <Button variant="outlined">Let's Get Started!</Button>
          </Link>
        </>
      )}
    </div>
  );
}
